import React from 'react'
import { Link } from 'gatsby'
import { Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Box, Button } from 'theme-ui'

export default () => (
  <>
    <Seo title='Thank You' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Thank You"
          subheader='A member of our team will be in contact shortly'
        />
        <Divider />
        <Box>
          <Button as={Link} to='/'>Back Home</Button>
        </Box>
      </Main>      
    </Stack>
  </>
)
